<template>
  <div>
    <!-- Navigation bar for authenticated users -->
    <v-app-bar app v-if="isAuthenticated">
      <v-toolbar-title>
        <v-icon left>mdi-store</v-icon>
        Store Directory App
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/" custom v-slot="{ navigate, href }">
        <v-btn :href="href" @click="navigate" text>
          <v-icon left>mdi-home</v-icon>
          Home
        </v-btn>
      </router-link>
      <router-link to="/directory" custom v-slot="{ navigate, href }">
        <v-btn :href="href" @click="navigate" text>
          <v-icon left>mdi-view-list</v-icon>
          Directory
        </v-btn>
      </router-link>
      <router-link to="/add" custom v-slot="{ navigate, href }">
        <v-btn :href="href" @click="navigate" text>
          <v-icon left>mdi-plus-box</v-icon>
          Add Store
        </v-btn>
      </router-link>
      <v-spacer></v-spacer>
      <v-btn @click="logout" text>
        <v-icon left>mdi-logout</v-icon>
        Logout
      </v-btn>
    </v-app-bar>

    <!-- Welcome prompt for non-authenticated users -->
    <div v-else class="welcome-container">
      <v-card class="welcome-card">
        <v-card-title>
          <v-icon left>mdi-login</v-icon>
          Welcome to the Store Directory App
        </v-card-title>
        <v-card-text>
          Please log in with your Microsoft account to continue.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="login" color="primary">
            <v-icon left>mdi-microsoft</v-icon>
            Login with Microsoft
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import msalInstance from '../msal';
import { loginRequest } from '../authConfig';

export default {
  name: 'NavigationBar',
  data() {
    return {
      isAuthenticated: false,
    };
  },
  methods: {
    async login() {
      try {
        const loginResponse = await msalInstance.loginPopup(loginRequest);
        console.log('Login successful:', loginResponse);
        this.isAuthenticated = true;
        this.$router.push('/profile');
      } catch (error) {
        console.error('Login error:', error);
      }
    },
    logout() {
      msalInstance.logoutPopup();
      this.isAuthenticated = false;
    },
  },
  mounted() {
    // Check if user is already authenticated on component mount
    const accounts = msalInstance.getAllAccounts();
    this.isAuthenticated = accounts.length > 0;
  },
};
</script>

<style>
.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.welcome-card {
  width: 400px;
  text-align: center;
  padding: 20px;
}
</style>
