// src/msal.js
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

export const initializeMsal = async () => {
  try {
    await msalInstance.initialize();
    console.log('MSAL initialized');
  } catch (error) {
    console.error('MSAL initialization error', error);
  }
};

export default msalInstance;
