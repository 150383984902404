<template>
    <v-container>
      <h1>Store Directory</h1>
      <StoreList :stores="stores" @deleteStore="deleteStore" />
    </v-container>
  </template>
  
  <script>
  import StoreList from '@/components/StoreList.vue';
  import { mapState, mapActions } from 'vuex';
  
  export default {
    name: 'StoreDirectoryView',
    components: {
      StoreList,
    },
    computed: {
      ...mapState(['stores']),
    },
    methods: {
      ...mapActions(['deleteStore']),
    },
  };
  </script>
  