<template>
    <v-list>
      <v-list-item
        v-for="store in stores"
        :key="store.id"
        @click="viewStore(store.id)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ store.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ store.address }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn @click.stop="editStore(store.id)" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click.stop="confirmDelete(store.id)" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </template>
  
  <script>
  export default {
    name: 'StoreList',
    props: {
      stores: Array,
    },
    methods: {
      viewStore(id) {
        this.$router.push({ name: 'StoreDetail', params: { id } });
      },
      editStore(id) {
        this.$router.push({ name: 'EditStore', params: { id } });
      },
      confirmDelete(id) {
        if (confirm('Are you sure you want to delete this store?')) {
          this.$emit('deleteStore', id);
        }
      },
    },
  };
  </script>
  