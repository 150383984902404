<template>
    <v-container>
      <h1>Edit Store</h1>
      <StoreForm :store="store" @submit="editStore" />
    </v-container>
  </template>
  
  <script>
  import StoreForm from '@/components/StoreForm.vue';
  import { mapState, mapActions } from 'vuex';
  
  export default {
    name: 'EditStoreView',
    components: {
      StoreForm,
    },
    computed: {
      ...mapState({
        store: (state, getters) => (id) => getters.getStoreById(id),
      }),
    },
    methods: {
      ...mapActions(['editStore']),
    },
  };
  </script>
  