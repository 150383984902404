<template>
    <v-form ref="form">
      <v-text-field v-model="form.name" label="Name" required></v-text-field>
      <v-text-field v-model="form.address" label="Address" required></v-text-field>
      <v-btn @click="submit">Submit</v-btn>
    </v-form>
  </template>
  
  <script>
  export default {
    name: 'StoreForm',
    props: {
      store: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        form: {
          id: this.store.id || null,
          name: this.store.name || '',
          address: this.store.address || '',
        },
      };
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.$emit('submit', { ...this.form });
        }
      },
    },
  };
  </script>
  