import { createStore } from 'vuex';

export default createStore({
  state: {
    stores: [
      { id: 1, name: 'Store 1', address: '123 Main St' },
      { id: 2, name: 'Store 2', address: '456 Oak Ave' },
      // Add more sample stores
    ],
  },
  mutations: {
    ADD_STORE(state, store) {
      state.stores.push({ ...store, id: Date.now() });
    },
    EDIT_STORE(state, updatedStore) {
      const index = state.stores.findIndex(store => store.id === updatedStore.id);
      if (index !== -1) {
        state.stores.splice(index, 1, updatedStore);
      }
    },
    DELETE_STORE(state, id) {
      state.stores = state.stores.filter(store => store.id !== id);
    },
  },
  actions: {
    addStore({ commit }, store) {
      commit('ADD_STORE', store);
    },
    editStore({ commit }, store) {
      commit('EDIT_STORE', store);
    },
    deleteStore({ commit }, id) {
      commit('DELETE_STORE', id);
    },
  },
  modules: {},
});
