<template>
  <div>
    <h1 v-if="isAuthenticated" >Welcome!</h1>
  </div>
</template>

<script>
import msalInstance from '../msal';
import { loginRequest } from '../authConfig';

export default {
  name: 'HomeView',
  data() {
    return {
      isAuthenticated: false,
    };
  },
  methods: {
    async login() {
      try {
        const loginResponse = await msalInstance.loginPopup(loginRequest);
        console.log('Login successful:', loginResponse);
        this.isAuthenticated = true;
        this.$router.push('/profile');
      } catch (error) {
        console.error('Login error:', error);
      }
    },
  },
  mounted() {
    const accounts = msalInstance.getAllAccounts();
    this.isAuthenticated = accounts.length > 0;
  },
};
</script>
