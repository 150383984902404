<template>
    <v-container>
      <h1>Add New Store</h1>
      <StoreForm @submit="addStore" />
    </v-container>
  </template>
  
  <script>
  import StoreForm from '@/components/StoreForm.vue';
  import { mapActions } from 'vuex';
  
  export default {
    name: 'AddStoreView',
    components: {
      StoreForm,
    },
    methods: {
      ...mapActions(['addStore']),
    },
  };
  </script>
  