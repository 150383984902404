import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import StoreDirectoryView from '../views/StoreDirectoryView.vue';
import AddStoreView from '../views/AddStoreView.vue';
import EditStoreView from '../views/EditStoreView.vue';
import ProfileView from '../views/ProfileView.vue';
import msalInstance from '../msal';

// Define the routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/directory',
    name: 'StoreDirectory',
    component: StoreDirectoryView,
  },
  {
    path: '/add',
    name: 'AddStore',
    component: AddStoreView,
  },
  {
    path: '/edit/:id',
    name: 'EditStore',
    component: EditStoreView,
    props: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
  },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Function to check if the user is authenticated
const isAuthenticated = () => {
  const accounts = msalInstance.getAllAccounts();
  return accounts.length > 0;
};

// Add a global navigation guard to check for authentication
router.beforeEach((to, from, next) => {
  console.log('Navigation attempt:', { from: from.fullPath, to: to.fullPath });
  if (!isAuthenticated() && to.path !== '/') {
    next('/');
  } else {
    next();
  }
});

export default router;
