import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import msalInstance, { initializeMsal } from './msal';
import { EventType } from '@azure/msal-browser';

loadFonts();

initializeMsal().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  // Set active account on page load
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event) => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  console.log('get active account', msalInstance.getActiveAccount());

  // handle auth redirect/do all initial setup for msal
  msalInstance.handleRedirectPromise().then(() => {
    // Check if user signed in 
    const account = msalInstance.getActiveAccount();
    if (!account) {
      // redirect anonymous user to login page 
      msalInstance.loginRedirect();
    }
  }).catch(err => {
    // TODO: Handle errors
    console.log(err);
  });

  const app = createApp(App);

  app.config.globalProperties.$msalInstance = msalInstance;

  app.use(router);
  app.use(store);
  app.use(vuetify);
  app.mount('#app');
});
