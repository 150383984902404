// src/authConfig.js
const isProduction = process.env.NODE_ENV === 'production';

export const msalConfig = {
  auth: {
    clientId: '97d7bd81-ac1b-47ce-b9ba-a7acc5015ab7',  // Replace with your client ID from Azure AD
    authority: 'https://login.microsoftonline.com/6b477cae-088d-4e51-bc77-f58aa2f11b2c', // Replace with your tenant ID
    redirectUri: isProduction
      ? 'https://admin.woworksusa.net'  // Production URI
      : 'http://localhost:8080',  // Development URI
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
