<template>
  <div>
    <h1>Profile</h1>
    <div v-if="account">
      <p><strong>Name:</strong> {{ account.name }}</p>
      <p><strong>Email:</strong> {{ account.username }}</p>
    </div>
  </div>
</template>

<script>
import msalInstance from '../msal';

export default {
  name: 'ProfileView',
  data() {
    return {
      account: null,
    };
  },
  async mounted() {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      this.account = accounts[0];
    } else {
      this.$router.push('/');
    }
  },
};
</script>
